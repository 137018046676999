import { useState } from "react";
import "./App.css";
import * as XLSX from "xlsx";
import axios from "axios";
import FileSaver from "file-saver";

import Progress from "react-progressbar";

function App() {
  const API_BASE_URL = "http://34.131.127.151"
  const AIR_ASIA_ID = 1;
  const FLY_DUBAI_ID = 2;
  const SPICEJET_ID = 3;
  const INDIGO_ID = 4;

  const airlinesArray = [
    {
      id: AIR_ASIA_ID,
      name: "Air Asia",
      url: (pnr, last) =>
        `${API_BASE_URL}:5002/air-asia/passenger_details/?pnr=${pnr}&last=${last}`,
    },
    {
      id: FLY_DUBAI_ID,
      name: "Fly Dubai",
      url: (pnr, last) =>
        `${API_BASE_URL}:8000/flydubai/retrieve?pnr=${pnr}&lastName=${last}`,
    },
    {
      id: SPICEJET_ID,
      name: "SpiceJet",
      url: (pnr, last) =>
        `${API_BASE_URL}:5001/spicejet/passenger_details/?pnr=${pnr}&last=${last}`,
    },
    {
      id: INDIGO_ID,
      name: "Indigo",
      url: (pnr, email) =>
        `http://34.131.127.151:8000/goindigo/retrieve?pnr=${pnr}&emailOrLastName=${email}`,
      // `https://airlines.scrapinghq.com/goindigo/retrieve?pnr=${pnr}&email=${email}`,
    },
  ];

  const [currAirline, setCurrAirline] = useState(airlinesArray[0]);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showDownload, setShowDownload] = useState(false);
  const [showRefundDownload, setShowRefundDownload] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [batchSize, setBatchSize] = useState(100);
  const [batchDelay, setBatchDelay] = useState(1);
  const [batchCount, setBatchCount] = useState(1);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [batchDelayMessage, setBatchDelayMessage] = useState("");

  const validateInput = () => {
    if (batchSize < 1) {
      alert("Batch size must be atleast 1");
      window.location.reload();
      return false;
    }
    return true;
  };

  const handleFileUpload = (e) => {
    if (!validateInput()) {
      return;
    }

    setShowDownload(false);
    setShowRefundDownload(false);
    setShowProgress(true);
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const inputSheetData = XLSX.utils.sheet_to_json(sheet, { header: "A" });

      const resultsArr = [];

      const processBatch = (fullList, batchSize, batchCount, currIndex) => {
        setCurrentBatch(currIndex);
        const start = batchSize * (currIndex - 1);
        const batchData = fullList.slice(start, start + batchSize);

        const promiseArr = [];

        for (let item of batchData) {
          const { A: pnr, B: lastName } = item;
          promiseArr.push(
            axios
              .get(currAirline.url(pnr, lastName))
              .then((response) => {
                console.log( response );
                if (currAirline.id === INDIGO_ID) {
                  resultsArr.push({
                    ...response,
                    pnr,
                  });
                } else {
                  resultsArr.push(response);
                }
                const progressPercent =
                  resultsArr.length > 0
                    ? Math.round((resultsArr.length / fullList.length) * 100)
                    : 0;
                setProgress(progressPercent);
              })
              .catch((error) => {
                console.log({ error });
                if (currAirline.id === FLY_DUBAI_ID) {
                  resultsArr.push({
                    error: error.response.data.error,
                    pnr,
                    lastName,
                  });
                }
                if (currAirline.id === INDIGO_ID) {
                  const errorMessage =
                    error.response.data.error?.result[0]?.message ||
                    "Unknown error occurred";
                  resultsArr.push({
                    error: errorMessage,
                    pnr,
                    lastName,
                  });
                }
              })
          );
        }

        Promise.allSettled(promiseArr)
          .then(async (_) => {
            if (currIndex < batchCount) {
              setBatchDelayMessage(`Batch delay of ${batchDelay} second(s)`);
              await new Promise((resolve) =>
                setTimeout(resolve, batchDelay * 1000)
              );
              setBatchDelayMessage("");
              processBatch(fullList, batchSize, batchCount, currIndex + 1);
            } else {
              setExcelData(
                resultsArr.map((item) => {
                  console.log(item)
                  if (currAirline.id === INDIGO_ID) {
                    if (item.error) {
                      return {
                        PNR: item.pnr,
                        Email: item.lastName,
                        BookingStatus: item.error,
                        Sector: "NA",
                        Status: "NA",
                      };
                    }

                    const { data } = item;

                    const { passengers, bookingDetails } = data;

                    let excelEmail = "NA";
                    let excelSector = "NA";
                    let excelStatus = "NA";

                    if (passengers && Array.isArray(passengers)) {
                      excelEmail = passengers.map((el) => el.name).join("\n");

                      excelSector = passengers
                        .map((passenger) => {
                          return passenger.seatsAndSsrs.journeys.map(
                            (journey) => {
                              return journey.segments.map((segment) => {
                                console.log(
                                  `Segment from ${segment.segmentDetails.origin} to ${segment.segmentDetails.destination}`
                                );
                                return `${segment.segmentDetails.origin} - ${segment.segmentDetails.destination}`;
                              });
                            }
                          );
                        })
                        .join("\n");

                      excelStatus = passengers
                        .map((passenger) => {
                          return passenger.seatsAndSsrs.journeys.map(
                            (journey) => {
                              return journey.segments.map((segment) => {
                                return `${segment.liftStatus}`;
                              });
                            }
                          );
                        })
                        .join("\n");
                    }

                    const excelBookingStatus =
                      bookingDetails?.bookingStatus || "NA";

                    return {
                      PNR: item.pnr,
                      Email: excelEmail,
                      "Booking Status": excelBookingStatus,
                      Sector: excelSector,
                      Status: excelStatus,
                    };
                  } else if (currAirline.id === FLY_DUBAI_ID) {
                    if (item.error) {
                      return {
                        PNR: item.pnr,
                        "Last Name": item.lastName,
                        "Passenger Details": item.error,
                      };
                    }

                    const {
                      pnr,
                      lastName,
                      departureDate,
                      departureTime,
                      passengersDetails,
                    } = item.data;

                    let excelPassDetails = passengersDetails
                      .map(
                        (item) =>
                          `Full Name: ${item.fullName}\nEmail: ${item.email}\nNo Show: ${item.isNoShow}`
                      )
                      .join("\n");

                    return {
                      PNR: pnr,
                      "Last Name": lastName,
                      "Passenger Details": excelPassDetails,
                      "Departure Date": departureDate,
                      "Departure Time": departureTime,
                    };
                  }

                  const { pnr, last, status, seat, designator, fares } =
                    item.data;

                  let excelStatus = "";
                  if (status) {
                    if (Array.isArray(status)) {
                      excelStatus = status.join("/n");
                    } else {
                      excelStatus = status;
                    }
                  }

                  let excelSeat = "";
                  if (seat && Array.isArray(seat)) {
                    excelSeat = seat.join("\n");
                  }

                  let excelSector = "",
                    excelDate = "";
                  if (designator) {
                    const { origin, destination, departure, arrival } =
                      designator;
                    if (origin && destination) {
                      excelSector = `${origin} - ${destination}`;
                    }
                    if (departure && arrival) {
                      excelDate = `${departure} = ${arrival}`;
                    }
                  }

                  if (currAirline.id === AIR_ASIA_ID) {
                    return {
                      PNR: pnr,
                      "Last Name": last,
                      Status: excelStatus,
                      Seat: excelSeat,
                      Sector: excelSector,
                      Date: excelDate,
                    };
                  } else if (currAirline.id === SPICEJET_ID) {
                    let excelFares = "";
                    if (fares) {
                      for (item in fares) {
                        excelFares += `${item}: ${fares[item]}\n`;
                      }
                    }

                    return {
                      PNR: pnr,
                      "Last Name": last,
                      Status: excelStatus,
                      Seat: excelSeat,
                      Sector: excelSector,
                      Date: excelDate,
                      Fares: excelFares,
                    };
                  }

                  return {};
                })
              );

              setShowDownload(true);
              setShowProgress(false);
            }
          })
          .catch((error) => console.log({ error }));
      };

      const numberOfBatches = Math.ceil(inputSheetData.length / batchSize);
      setBatchCount(numberOfBatches);
      processBatch(inputSheetData, batchSize, numberOfBatches, 1);
    };

    reader.readAsBinaryString(e.target.files[0]);
  };

  const AirlineButton = ({ data }) => {
    return (
      <button
        className={`text-3xl rounded px-4 py-2 ${
          currAirline.id === data.id ? "bg-green-600" : "bg-transparent"
        }`}
        onClick={() => setCurrAirline(data)}
      >
        {data.name}
      </button>
    );
  };

  const DownloadButton = ({ apiData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      window.location.reload();
    };

    return (
      <button
        className="bg-blue-700 px-4 py-2 rounded text-xl mx-auto"
        onClick={(e) => exportToCSV(apiData, fileName)}
      >
        Download Excel file
      </button>
    );
  };

  const handleRefundFileUpload = (e) => {
    if (!validateInput()) {
      return;
    }

    setShowDownload(false);
    setShowRefundDownload(false);
    setShowProgress(true);
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const inputSheetData = XLSX.utils.sheet_to_json(sheet, { header: "A" });

      const resultsArr = [];

      const processBatch = (fullList, batchSize, batchCount, currIndex) => {
        setCurrentBatch(currIndex);
        const start = batchSize * (currIndex - 1);
        const batchData = fullList.slice(start, start + batchSize);

        const promiseArr = [];

        for (let item of batchData) {
          const { A: pnr, B: emailOrLastName } = item;
          promiseArr.push(
            axios
              .post("http://35.238.58.220:8000/goindigo/refund", {
                pnr,
                emailOrLastName,
              })
              .then((response) => {
                resultsArr.push({ ...response, emailOrLastName });
                const progressPercent =
                  resultsArr.length > 0
                    ? Math.round((resultsArr.length / fullList.length) * 100)
                    : 0;
                setProgress(progressPercent);
              })
              .catch((error) => console.log({ error }))
          );
        }

        Promise.allSettled(promiseArr)
          .then(async (_) => {
            if (currIndex < batchCount) {
              setBatchDelayMessage(`Batch delay of ${batchDelay} second(s)`);
              await new Promise((resolve) =>
                setTimeout(resolve, batchDelay * 1000)
              );
              setBatchDelayMessage("");
              processBatch(fullList, batchSize, batchCount, currIndex + 1);
            } else {
              setExcelData(
                resultsArr.map((item) => {
                  console.log({ item });
                  if (item.error) {
                    const { pnr, result } = item.error;
                    const { message } = result[0];
                    return {
                      PNR: pnr,
                      Email: item.emailOrLastName,
                      "Booking Status": message,
                    };
                  }

                  const { pnrDetails } = item.data;

                  let excelPNR = "",
                    excelEmail = "",
                    excelBookingStatus = "",
                    excelPaymentStatus = "",
                    excelCurrency = "",
                    excelRefundAmount = "";

                  if (pnrDetails.bookingDetails) {
                    const { bookingDetails, indigoTaxRefund } = pnrDetails;

                    const {
                      recordLocator,
                      paymentStatus,
                      bookingStatus,
                      currencyCode,
                    } = bookingDetails;

                    const { refundAmount } = indigoTaxRefund;

                    excelPNR = recordLocator;
                    excelEmail = item.emailOrLastName;
                    excelBookingStatus = bookingStatus;
                    excelPaymentStatus = paymentStatus;
                    excelCurrency = currencyCode;
                    excelRefundAmount = refundAmount;
                  } else if (pnrDetails.pnr) {
                    const { pnr, email, paymentStatus, bookingStatus, refund } =
                      pnrDetails;

                    excelPNR = pnr;
                    excelEmail = email;
                    excelPaymentStatus = paymentStatus;
                    excelBookingStatus = bookingStatus;
                    excelRefundAmount = refund.refundAmount;
                  }

                  return {
                    PNR: excelPNR,
                    Email: excelEmail,
                    "Booking Status": excelBookingStatus,
                    "Payment Status": excelPaymentStatus,
                    Currency: excelCurrency,
                    "Refund Amount": excelRefundAmount,
                  };
                })
              );

              setShowRefundDownload(true);
              setShowProgress(false);
            }
          })
          .catch((error) => console.log({ error }));
      };

      const numberOfBatches = Math.ceil(inputSheetData.length / batchSize);
      setBatchCount(numberOfBatches);
      processBatch(inputSheetData, batchSize, numberOfBatches, 1);
    };

    reader.readAsBinaryString(e.target.files[0]);
  };

  return (
    <div className=" h-screen bg-cyan-900 text-white p-3 flex flex-col justify-center items-center w-100">
      <div className="w-1/2 flex flex-col justify-center items-center">
        <div className="flex flex-row gap-4 items-center">
          <span className="text-lg">Batch Size: </span>
          <input
            className="text-black my-4 p-1 w-16"
            type="text"
            value={batchSize}
            onChange={(event) => {
              const replaceStr = event.target.value.replace(/\D/g, "");
              const numberInput = Number(replaceStr);
              setBatchSize(numberInput);
            }}
          />
        </div>

        <div className="flex flex-row gap-4 items-center">
          <span className="text-lg">Batch Delay: </span>
          <input
            className="text-black my-4 p-1 w-16"
            type="text"
            value={batchDelay}
            onChange={(event) => {
              const replaceStr = event.target.value.replace(/\D/g, "");
              const numberInput = Number(replaceStr);
              setBatchDelay(numberInput);
            }}
          />
        </div>

        <hr className="h-px w-full my-8 bg-white" />

        <div className="flex flex-row gap-20">
          {airlinesArray.map((airlineObj,i) => (
            <AirlineButton data={airlineObj} key={i}/>
          ))}
        </div>

        <div>
          <input
            disabled={showProgress}
            type="file"
            id="excel-file-picker"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="mt-8 ml-24"
          />
        </div>

        <hr className="h-px w-full my-8 bg-white" />

        <p className="text-3xl text-white">Indigo Refund</p>
        <input
          disabled={showProgress}
          type="file"
          id="excel-refund-file-picker"
          accept=".xlsx, .xls"
          onChange={handleRefundFileUpload}
          className="mt-8 ml-24"
        />

        <hr className="h-px w-full my-8 bg-white" />

        <div className="mt-4 flex flex-col justify-center items-center w-full">
          {showProgress && (
            <div className="flex flex-row items-center w-full">
              <p className="mx-4">
                {batchDelayMessage
                  ? batchDelayMessage
                  : `Current Batch: ${currentBatch}/${batchCount}`}
              </p>
              <p className="mx-1">{progress}%</p>
              <div className="grow">
                <Progress completed={progress} />
              </div>
            </div>
          )}
          {showDownload && (
            <DownloadButton
              apiData={excelData}
              fileName={`${currAirline.name
                .split(" ")
                .join("_")
                .toLowerCase()}_report`}
            />
          )}
          {showRefundDownload && (
            <DownloadButton
              apiData={excelData}
              fileName={"indigo_refund_report"}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
